<template>
	<div class="about">
		<Promo/>	
		<Social/>
		<DialogBox>

			<template v-slot:title>
				<h2>Miapuestaweb</h2>
			</template>

			<template v-slot:subtitle>
				<p>Somos una organización especializada en apuestas online que ofrece un servicio absolutamente integral en tiempo real, a través del cual usted podrá realizar todo tipo de apuestas deportivas por medio del área interactiva de la página.</p>
			</template>

			<template v-slot:body>
				<div class="box__content__body__subtitle mission">
					<h3>Misión</h3>
				</div>
				<p>Satisfacer las necesidades de nuestros clientes en el menor tiempo posible y manteniendo siempre la calidad de nuestra página, conservando la oportuna respuesta ante las necesidades de cada uno de ellos, manteniéndolos al día e informados de todo lo relacionado al mundo deportivo y las apuestas.</p>
				<div class="box__content__body__subtitle vision">
					<h3>Visión</h3>
				</div>
				<p>Consolidar nuestro posicionamiento en un mercado altamente competitivo como lo son las casas de apuestas, reconocidas por los años de experiencia, por la envergadura de sus clientes, excelencia de sus trabajos y solvencia económica, permitiéndonos cumplir eficientemente con todas sus exigencias y requerimientos; logrando la adquisición de nuevas cuentas tanto a nivel nacional como internacional y el desarrollo de nuevas alianzas.</p>
			</template>

		</DialogBox>
		<transition appear name="bounce" enter-active-class="bounceInRight">
			<img id="rigth-bars" class="bars hide-mobile" src="../assets/img/rigth-bars.png" alt="">
		</transition>
		<transition appear name="bounce" enter-active-class="bounceInUp">
			<img id="left-bar" class="bars hide-mobile" style="animation-duration: 1.5s" src="../assets/img/left-bar.png" alt="">
		</transition>
		<transition appear name="bounce" enter-active-class="bounceInRight">
			<img id="orange-bars" class="bars hide-mobile" src="../assets/img/orange-bars.png" alt="">
		</transition>		
	</div>
</template>
<script>
import Promo from '../components/Promo.vue'
import Social from '../components/Social.vue'
import DialogBox from '../components/DialogBox.vue'
	export default {
		name: 'About',
		title: 'Nosotros',
		components: {
			Promo,
			Social,
			DialogBox,
		}
	}
</script>

<style lang="scss" scoped>

	.about {
		width: 100%;
		height: 100%;
		border: 1px solid transparent;
		margin-top: -60%;
	}

	.bars {
		position: absolute;
		z-index: 1;
	}	

	#rigth-bars {
    	right: -3.5%;
    	bottom: 68.7%;
	}

	#left-bar {
		left: 43.4%;
		bottom: 47.6%;
	}

	#orange-bars {
		right: 3.1%;
    	bottom: 68.7%;
		animation-duration: 2s;
	}

	@include desktopMax {

		.about {

			&__box {

				&__content {

					&__body {
						height: 130vh;
					}
				}
			}
		}
	}	
	
	@include mobile {

		.about {
			margin-top: 0;
		}
	}
</style>